import React, { useContext, useState } from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useLocation,
  NavLink,
} from 'react-router-dom';

import { useSignIn } from '@airhelp/session';
import { LoginParams } from '@airhelp/auth';
import { AuthFormOAuth, AuthSigninForm } from '@airhelp/funnel';
import { Link, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { SSO_PROVIDER, oAuthLink } from 'api/auth/auth';
import { USER_RETURN_AHPLUS_COOKIE } from 'config/cookies';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import {
  prefetchMyProfile,
  useMyProfile,
} from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { createCookieOptions } from 'utils/cookies';
import { useTrackEvent } from 'utils/tracking/hooks';

const LoginView: React.FC = () => {
  const queryClient = useQueryClient();
  const { setLoginData } = useContext(TrackingContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { clearProfile } = useMyProfile();
  const [apiError, setApiError] = useState<string | null>();
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();

  const locationState = location.state as Record<string, string>;
  const locationFrom = locationState?.from;

  const { mutate: authLogin, isPending } = useSignIn({
    onError: (error) => {
      const errorMessage = handleErrorMessage(error);
      setApiError(errorMessage);
    },
    onSuccess: async () => {
      clearProfile();

      const userProfile = await prefetchMyProfile(queryClient);

      if (userProfile) {
        setLoginData({ method: 'email', user: userProfile });

        const hasNoCoverage = !userProfile.policy.coverage.active;

        navigate({
          pathname:
            hasNoCoverage && locationFrom
              ? locationFrom
              : generatePath(PAGE_PATHS.HOME_PAGE),
        });
      } else {
        navigate({ pathname: generatePath(PAGE_PATHS.HOME_PAGE) });
      }
    },
  });

  const saveCookieUserReturnUrl = () => {
    cookie.save(USER_RETURN_AHPLUS_COOKIE, '1', createCookieOptions());
  };

  const handleErrorMessage = (errorResponse) => {
    let errorMessage;

    if (errorResponse?.response?.status === 401) {
      errorMessage = t('errors.invalid_email_or_password');
    } else {
      const errorBody = errorResponse?.response;
      errorMessage = errorBody?.error || t('errors.something_went_wrong');
    }

    return errorMessage;
  };

  const onSubmit = async (data: LoginParams) => {
    const { email, password } = data;
    authLogin({ email, password });
  };

  return (
    <AuthSigninForm
      i18n={{
        title: t('authentication.login_form.log_in_to_continue'),
        actionText: t('authentication.login_form.submit'),
        divider: t('authentication.login_form.login_via'),
        emailPlaceholder: t('common.email'),
        passwordPlaceholder: t('authentication.password'),
        forgotYourPassword: (
          <Text as="span" fontSize="sm" color="greyscale.600">
            {t('authentication.forgot_your_password')}
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.RESET_PASSWORD_PAGE)}
              onClick={() => {
                trackCtaClick('reset password', 'login page');
                saveCookieUserReturnUrl();
              }}
              pl={1}
              fontSize="sm"
              fontWeight="normal"
              color="primary.500"
              _visited={{}}
            >
              <Text as="span" fontSize="sm" color="primary.500">
                {t('authentication.reset_it_now')}
              </Text>
            </Link>
          </Text>
        ),
        signinCallout: (
          <Text as="span" fontSize="sm" color="greyscale.600">
            {t('authentication.do_not_have_an_account')}
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.SIGNUP_PAGE)}
              onClick={() => {
                trackCtaClick('create account', 'signup page');
              }}
              pl={1}
              fontSize="sm"
              fontWeight="normal"
              color="primary.500"
              _visited={{}}
            >
              <Text as="span" fontSize="sm" color="primary.500">
                {t('authentication.create_free_account_now')}
              </Text>
            </Link>
          </Text>
        ),
      }}
      oAuth={
        <AuthFormOAuth
          facebookLink={oAuthLink(SSO_PROVIDER.FACEBOOK)}
          onFacebookClick={() => {
            trackCtaClick('facebook sso', 'login via facebook sso');
          }}
          googleLink={oAuthLink(SSO_PROVIDER.GOOGLE)}
          onGoogleClick={() => {
            trackCtaClick('google sso', 'login via google sso');
          }}
          microsoftLink={oAuthLink(SSO_PROVIDER.MICROSOFT)}
          onMicrosoftClick={() => {
            trackCtaClick('microsoft sso', 'login via microsoft sso');
          }}
        />
      }
      formError={apiError ? apiError : undefined}
      onSubmit={(data) => {
        trackCtaClick('login', 'login page');
        onSubmit(data);
      }}
      isSubmitting={isPending}
    />
  );
};

export default LoginView;
