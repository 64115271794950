import { useCallback, useContext } from 'react';

import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTracking } from 'react-tracking';
import { EditedJourneyContext } from 'contexts';
import { CustomTrackEvent, TrackEventData } from '../props/ga4/types';
import { transformJourneyData } from '../props/ga4/utils';

export const useTrackEvent = () => {
  const { userProfile } = useMyProfile();
  const { journey, isReturnTrip } = useContext(EditedJourneyContext);
  const { trackEvent } = useTracking<TrackEventData>();

  const trackCtaClick = useCallback(
    (label: string, placement: string) => {
      trackEvent({
        name: CustomTrackEvent.CTA_CLICK,
        label,
        placement,
      });
    },
    [trackEvent],
  );

  const trackPageInteractions = useCallback(
    (action: string, label: string, placement?: string) => {
      trackEvent({
        name: CustomTrackEvent.PAGE_INTERACTIONS,
        action,
        label,
        placement,
      });
    },
    [trackEvent],
  );

  const trackUpsellOption = useCallback(
    (label: string, option: string) => {
      trackEvent({
        name: CustomTrackEvent.UPSELL_OPTION,
        label,
        option,
      });
    },
    [trackEvent, userProfile],
  );

  const trackOnboardingFunnel = useCallback(
    (label: string) => {
      trackEvent({
        name: CustomTrackEvent.ONBOARDING_FUNNEL,
        label,
      });
    },
    [trackEvent, userProfile],
  );

  const trackAddFlightFunnel = useCallback(
    (label: string) => {
      const flightData = transformJourneyData(journey, isReturnTrip);

      trackEvent({
        name: CustomTrackEvent.ADD_TRIP,
        label,
        ...flightData,
      });
    },
    [trackEvent, userProfile],
  );

  return {
    trackCtaClick,
    trackPageInteractions,
    trackOnboardingFunnel,
    trackUpsellOption,
    trackAddFlightFunnel,
  };
};
