import React from 'react';

import { Flex } from '@chakra-ui/react';

import TopBannerEssentialUpgrade from 'components/HomeView/TopBannerEssentialUpgrade';
import TopBannerPaymentManagement from 'components/HomeView/TopBannerPaymentManagement';

interface IComponent {
  showTopBannerEssentialUpgrade: boolean;
  showPaymentManagementBanner: boolean;
}

const TopBanners: React.FC<IComponent> = ({
  showTopBannerEssentialUpgrade,
  showPaymentManagementBanner,
}) => {
  return (
    <Flex flexDirection="column" position="fixed" zIndex="11" width="100%">
      {showTopBannerEssentialUpgrade ? <TopBannerEssentialUpgrade /> : null}
      {showPaymentManagementBanner ? <TopBannerPaymentManagement /> : null}
    </Flex>
  );
};

export default TopBanners;
