import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate } from 'react-router-dom';
import track from 'react-tracking';

import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { usePageTracking } from 'utils/tracking';
import PaymentManagementView from 'components/MyAccountView/BillingView';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { Loader } from 'elements';

const BillingPage: React.FC = () => {
  const { t } = useTranslation();
  usePageTracking();

  const {
    isLoading,
    isError: isErrorProfile,
    data: userProfile,
  } = useFetchMyProfile();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  if (isLoading || !userProfile) {
    return <Loader dataTestId="loader" />;
  }

  if (isErrorProfile) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  const showPaymentManagement =
    userProfile?.policy.membership?.showPaymentManagementPage;

  if (!showPaymentManagement) {
    return <Navigate to={generatePath(PAGE_PATHS.HOME_PAGE)} />;
  }

  return <PaymentManagementView />;
};

export default track({ page_type: 'MyAccount.Billing' })(BillingPage);
