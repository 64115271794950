import { useCallback, useContext } from 'react';
import { TrackingContext } from 'contexts';
import { useTracking } from 'react-tracking';
import {
  TrackCheckoutEvent,
  EcommerceEvent,
  CustomTrackEvent,
  TrackEventData,
} from '../props/ga4/types';
import { isLocalStorageAvailable } from '../props/ga4/utils';
import {
  createCheckoutEcommerce,
  productTierForOffer,
  productTierForChannel,
  productTier,
} from '../props/ga4/checkout';

type PageType = 'Email' | 'Checkout' | 'Processing' | 'Failed' | 'Succeed';

export const useTrackCheckout = () => {
  const { checkoutData } = useContext(TrackingContext);
  const { trackEvent: trackEcommerceEvent } = useTracking<TrackCheckoutEvent>();
  const { trackEvent } = useTracking<TrackEventData>();

  const trackCheckout = useCallback(
    (event: EcommerceEvent) => {
      if (!isLocalStorageAvailable()) {
        return;
      }

      const checkoutEvent = {
        name: event,
        ecommerce: createCheckoutEcommerce(checkoutData),
      };

      trackEcommerceEvent(checkoutEvent);
    },
    [checkoutData],
  );

  const trackSubscriptionFunnel = useCallback(
    (pageType: PageType) => {
      if (!isLocalStorageAvailable()) {
        return;
      }

      const urlSearchParams = new URLSearchParams(location.search);

      trackEvent({
        name: CustomTrackEvent.SUBSCRIPTION_FUNNEL,
        type: productTierForOffer(checkoutData.productDetails)
          ? productTier(urlSearchParams.get('tier') || '')
          : productTierForChannel(urlSearchParams.get('channel') || ''),
        label: `Membership - ${pageType}`,
      });
    },
    [checkoutData],
  );

  const trackPaymentFailed = useCallback(() => {
    trackEvent({ name: CustomTrackEvent.FAILED_PAYMENT });
  }, [checkoutData]);

  return { trackCheckout, trackSubscriptionFunnel, trackPaymentFailed };
};
