import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { Button } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { TranslationKey } from 'types/i18n';
import { AirPayoutJourney, AirPayoutStatus } from '@airhelp/plus';
import { useTrackEvent } from 'utils/tracking/hooks';

const AirPayoutAction = ({
  airPayoutJourney,
  journeyId,
}: {
  airPayoutJourney: AirPayoutJourney;
  journeyId: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const { status } = airPayoutJourney;
  const getPaidHandler = (update: boolean) => {
    trackCtaClick(
      update ? 'update bank details' : 'get paid',
      'ahp airPayout page',
    );

    const page = update
      ? PAGE_PATHS.JOURNEY.AIR_PAYOUT.PAYOUT_UPDATE_PAGE
      : PAGE_PATHS.JOURNEY.AIR_PAYOUT.PAYOUT_PAGE;
    navigate(generatePath(page, { id: journeyId.toString() }));
  };

  const getAdditionalContent = (tKey: TranslationKey, update = false) => {
    const id = update
      ? 'air-payout-update-bank-details-button'
      : 'air-payout-get-paid-button';
    return (
      <Button
        onClick={() => getPaidHandler(update)}
        size="xs"
        backgroundColor="danger.500"
        color="greyscale.100"
        maxHeight="32px"
        _hover={{ backgroundColor: 'danger.400' }}
        data-testid={id}
      >
        {t(tKey)}
      </Button>
    );
  };

  if (status === AirPayoutStatus.ELIGIBLE) {
    return getAdditionalContent('common.get_paid');
  }
  if (
    status === AirPayoutStatus.PAYOUT_FAILED ||
    status === AirPayoutStatus.PAYOUT_RETURNED
  ) {
    return getAdditionalContent('common.update_bank_details', true);
  }
  return t('common.no_action');
};

export default AirPayoutAction;
