import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { BoxImportant } from '@airhelp/icons';
import { useToast, useBreakpointValue } from '@chakra-ui/react';
import { useUpdatePaymentIntent } from '@airhelp/plus/react';
import { usePreferencesStore } from 'stores/preferences';
import { type CurrencyCode } from 'stores/preferences/currencies';
import {
  type IStripePaymentStore,
  useStripeStore,
} from 'stores/preferences/stripe';

type TStripePaymentContext = Omit<
  IStripePaymentStore,
  'setStripeElementId' | 'setIsLoading'
> & {
  changeCurrency: (
    currency: CurrencyCode,
    paymentIntentId: string,
  ) => Promise<void>;
};

const StripePaymentContext = createContext<TStripePaymentContext>({
  paymentDetails: null,
  setPaymentDetails: () => null,
  paymentIntentId: null,
  changeCurrency: () => Promise.resolve(),
  setPaymentIntentId: () => null,
  stripeElementId: 0,
  isLoading: false,
});

const StripePaymentContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    paymentDetails,
    paymentIntentId,
    setPaymentIntentId,
    stripeElementId,
    setStripeElementId,
    setPaymentDetails,
    isLoading,
    setIsLoading,
  } = useStripeStore();
  const { currency, setCurrency } = usePreferencesStore();
  const [searchParams] = useSearchParams();
  const { mutateAsync: updatePaymentIntent } = useUpdatePaymentIntent();

  const isUpgrade = searchParams.get('upgrade') === 'true';

  const toast = useToast();
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const changeCurrency = async (currency: string, paymentIntentId: string) => {
    const previousCurrency = paymentDetails?.currency;
    try {
      setIsLoading(true);
      const data = await updatePaymentIntent({
        currency,
        id: paymentIntentId,
        upgrade: isUpgrade,
      });
      setPaymentDetails(data);
      setStripeElementId();
    } catch (error) {
      toast({
        title: t('errors.something_went_wrong'),
        status: 'error',
        duration: 4000,
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: (
          <BoxImportant boxSize={5} alignSelf="center" color="greyscale.100" />
        ),
      });
      if (previousCurrency) {
        setCurrency(previousCurrency as CurrencyCode);
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentIntentId) {
      changeCurrency(currency, paymentIntentId);
    }
  }, [currency]);

  return (
    <StripePaymentContext.Provider
      value={{
        paymentDetails,
        paymentIntentId,
        changeCurrency,
        setPaymentIntentId,
        setPaymentDetails,
        stripeElementId,
        isLoading,
      }}
    >
      {children}
    </StripePaymentContext.Provider>
  );
};

const useStripePayment = () => {
  return useContext(StripePaymentContext);
};

export default StripePaymentContextProvider;
export { StripePaymentContext, useStripePayment };
