import { EditableJourney } from '@airhelp/plus';

export const transformJourneyData = (
  journey: EditableJourney,
  returnTrip: boolean,
) => {
  const departureAirport =
    journey?.itineraries[0]?.flights[0]?.departureAirport;

  const departureAirportIata = departureAirport?.iata;
  const departureAirportName = departureAirport?.name;

  const arrivalAirport = journey?.itineraries
    ?.slice(-1)[0]
    ?.flights?.slice(-1)[0]?.arrivalAirport;

  const arrivalAirportIata = arrivalAirport?.iata;
  const arrivalAirportName = arrivalAirport?.name;

  const flightsQuantity = journey.itineraries.reduce(
    (noOfFlights, itinerary) => noOfFlights + itinerary.flights.length,
    0,
  );

  return {
    trip_departure: `${departureAirportName}, ${departureAirportIata}`,
    trip_destination: `${arrivalAirportName}, ${arrivalAirportIata}`,
    departure_date: journey?.itineraries[0]?.flights[0]?.localDepartureDate,
    trip_type: returnTrip ? 'return' : 'one way',
    flights_quantity: flightsQuantity,
  };
};

export function isLocalStorageAvailable() {
  try {
    const TEST_KEY = 'ahplus-local-storage-test';
    localStorage.setItem(TEST_KEY, JSON.stringify({}));
    localStorage.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
}
