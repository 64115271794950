import { useTranslation, Trans } from 'react-i18next';
import { Flex, Box, VStack, Button, Text } from '@chakra-ui/react';
import { generatePath, Link as ReactLink } from 'react-router-dom';
import { useTrackEvent } from 'utils/tracking/hooks';

import { PlanBoxHeader } from './PlanBoxHeader';
import { AnnualSupport } from './AnnualSupport';
import { TripProtection } from './TripProtection';
import { TravelPerks } from './TravelPerks';
import {
  CurrencyCode,
  formatValueToCurrency,
} from 'stores/preferences/currencies';
import { PAGE_PATHS } from 'config/routes';
import { SMART_UPGRADE_FIRST_YEAR_SAVE_AMOUNT } from 'config/benefits';
import { ESSENTIAL_TO_SMART_UPGRADE } from 'config/channels';
import {
  EcommerceEvent,
  TrackEcommerceEvent,
  Item,
} from 'utils/tracking/props/ga4/types';
import { useTracking } from 'react-tracking';

interface SmartPlanProps {
  price: string;
  discountedPrice: string;
  currency: CurrencyCode;
  ecommerceItem: Item;
}

export function SmartPlan({
  price,
  discountedPrice,
  currency,
  ecommerceItem,
}: SmartPlanProps) {
  const { t } = useTranslation();
  const { trackEvent } = useTracking<TrackEcommerceEvent>();

  const { trackCtaClick } = useTrackEvent();

  const handleDiscoverClick = () => {
    trackCtaClick('upgrade now', 'essential to smart landing page');
    trackEvent({
      event: EcommerceEvent.SELECT_ITEM,
      ecommerce: {
        currency,
        item_list_name: 'essential_smart_upgrade',
        items: [ecommerceItem],
      },
    });
  };

  const monthPrice = (Number(price) / 12).toFixed(2);

  const monthPriceDiscounted = (Number(discountedPrice) / 12).toFixed(2);

  return (
    <VStack
      border="1px solid #DEE5ED"
      borderRadius="lg"
      maxW="384px"
      backgroundColor="greyscale.200"
    >
      <PlanBoxHeader
        badge={t('special_offer.save', {
          amount: SMART_UPGRADE_FIRST_YEAR_SAVE_AMOUNT,
        })}
        planName="Smart"
        subheading={t('special_offer.new_plan')}
        version="secondary"
      />
      <Box p="0 32px 32px 32px" w="100%">
        <TripProtection lostBagProtectionEnabled loungeEnabled />
        <AnnualSupport />
        <TravelPerks additionalSavingsEnabled />
        <Flex
          w="100%"
          flexDirection="column"
          alignItems="center"
          mt={{
            base: '20px',
            md: '32px',
          }}
        >
          <Button
            w="100%"
            h="48px"
            fontSize="small"
            onClick={handleDiscoverClick}
            as={ReactLink}
            to={{
              pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
              search: `?tier=smart&channel=${ESSENTIAL_TO_SMART_UPGRADE}&upgrade=true`,
            }}
          >
            <Trans
              i18nKey="special_offer.upgrade_for_with_discount"
              values={{
                initialPrice: formatValueToCurrency(price, currency),
                price: formatValueToCurrency(discountedPrice, currency),
              }}
              components={{
                span: (
                  <Text
                    display="inline"
                    textDecoration="line-through"
                    color="greyscale.100"
                    fontSize="xs"
                    fontWeight="500"
                    as="span"
                    mr="1"
                  />
                ),
              }}
            />
          </Button>
          <Text as="span" color="greyscale.600" fontSize="small" mt="4">
            <Trans
              i18nKey="special_offer.price_per_month_with_discount"
              values={{
                initialPrice: formatValueToCurrency(monthPrice, currency),
                price: formatValueToCurrency(monthPriceDiscounted, currency),
              }}
              components={{
                span: (
                  <Text
                    fontSize="small"
                    display="inline"
                    as="span"
                    textDecoration="line-through"
                    color="greyscale.600"
                  />
                ),
              }}
            />
          </Text>
        </Flex>
      </Box>
    </VStack>
  );
}
