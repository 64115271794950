export enum CustomTrackEvent {
  PAGE_VIEW = 'page_view',
  CTA_CLICK = 'cta_click',
  PAGE_INTERACTIONS = 'page_interactions',
  UPSELL_OPTION = 'upsell_option',
  ONBOARDING_FUNNEL = 'onboarding_funnel',
  ADD_TRIP = 'add_trip',
  SUBSCRIPTION_FUNNEL = 'subscription_funnel',
  FAILED_PAYMENT = 'failed_payment',
}

export interface TrackEventData {
  name: CustomTrackEvent;
  label?: string;
  placement?: string;
  action?: string;
  type?: string;
  [key: string]: unknown;
}

export enum EcommerceEvent {
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase_ga',
  ADD_PAYMENT_INFO = 'add_payment_info',
  VIEW_ITEM = 'view_item_list',
  SELECT_ITEM = 'select_item',
}

export interface UserTrackingData {
  active_subscription: string | null;
  business_type: string | null;
  hashed_email_id: string | null;
  is_logged: boolean;
  user_id: number | null;
}

export interface Item {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  discount?: string | number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_list_name?: string;
  item_variant: string;
  price?: string | number;
  quantity: number;
}

export interface TrackCheckoutEvent {
  name: EcommerceEvent;
  ecommerce: {
    transaction_id: string;
    currency: string;
    value: string | number;
    coupon: string;
    payment_type: string;
    items: readonly Item[];
  };
}

export interface TrackEcommerceEvent {
  event: EcommerceEvent;
  ecommerce: {
    currency: string;
    item_list_name?: string;
    items: readonly Item[];
  };
}
