import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate } from 'react-router-dom';
import track from 'react-tracking';

import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { usePageTracking } from 'utils/tracking';
import SpecialOfferView from 'components/MyAccountView/SpecialOfferView';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { PageLoader } from 'elements';

const SpecialOfferPage: React.FC = () => {
  usePageTracking();
  const { t } = useTranslation();
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  if (isUserProfileLoading) {
    return <PageLoader />;
  }

  const allowPageAccess = userProfile?.policy?.membership?.isEssential;

  if (!allowPageAccess) {
    return <Navigate to={generatePath(PAGE_PATHS.HOME_PAGE)} />;
  }

  return <SpecialOfferView />;
};

export default track({ page_type: 'MyAccount.SpecialOffer' })(SpecialOfferPage);
