import { Expose, plainToClass } from 'class-transformer';
import { type LoungePass as ILoungePass } from '@airhelp/plus';

export class LoungePass implements ILoungePass {
  @Expose() imageUrl: string;
  @Expose() passCode: string;
  @Expose() passengerName: string;
  @Expose() validUntil: string;
}

export function transformToLoungePassClass(
  loungePass: ILoungePass,
): LoungePass {
  return plainToClass(LoungePass, loungePass);
}
