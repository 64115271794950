import { get, AHPLUS_API_URL } from 'api/ky';

export const getStripePaymentStatus = async (intentId: string) => {
  return await get(`${AHPLUS_API_URL}/api/v2/stripe_checkouts/${intentId}`);
};

export const getStripeOrder = async (intentId: string) => {
  return await get(
    `${AHPLUS_API_URL}/api/v2/payments_confirmations/${intentId}`,
  );
};
