import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import {
  fetchAirBundleSubscription,
  FetchAirBundleSubscriptionResponse,
} from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';
import { transformToFetchAirBundleSubscriptionClass } from 'models/userProfile/AirBundleSubscription';

const useFetchAirBundleSubscription = (
  options?: ApiQueryOptions<FetchAirBundleSubscriptionResponse>,
) => {
  return useProtectedQuery({
    queryKey: ['userProfile', 'airBundleSubscription'],
    queryFn: () => fetchAirBundleSubscription({ client: ahplusClient }),
    select: (airBundleSubscription) =>
      transformToFetchAirBundleSubscriptionClass(airBundleSubscription),
    ...options,
  });
};

export default useFetchAirBundleSubscription;
