import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex } from '@chakra-ui/react';

import AirDocumentsUploader from 'elements/AirDocumentsUploader/AirDocumentsUploader';
import useEffectOnce from 'hooks/useEffectOnce';
import { type TranslationKey } from 'types/i18n';
import {
  AirPayoutJourney,
  AirPayoutPaymentPax,
  AirBundleDocumentFile,
} from '@airhelp/plus';
import { dataTestIdFormat } from 'utils/dataTestIdFormat';

const AIR_PAYOUT_DOCUMENTS: { name: string; tKey: TranslationKey }[] = [
  {
    name: `${AirBundleDocumentFile.BOARDING}File`,
    tKey: 'air_luggage.luggage_details.documents.boarding_pass_or_booking_reference',
  },
  {
    name: `${AirBundleDocumentFile.PASSPORT}File`,
    tKey: 'air_luggage.luggage_details.documents.passport_or_id',
  },
];

type IReducerDataById = Record<number, AirPayoutPaymentPax>;

const DetailsForm = ({
  form,
  airPayoutJourney,
  handleAddPax,
  canEdit,
  reducerDataById,
  setFormsValidity,
}: {
  form: UseFormReturn;
  airPayoutJourney: AirPayoutJourney;
  handleAddPax: (data) => void;
  canEdit: boolean;
  reducerDataById: IReducerDataById;
  setFormsValidity: (id: number, valid: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, clearErrors, trigger } = form;
  const { id } = airPayoutJourney;

  const handleDocument = (type: string, files: File[]) => {
    setValue(type, files[0]);
    clearErrors(type);
  };
  const handleDocumentDelete = (type: string): void => {
    setValue(type, null);
    setFormsValidity(id, false);
    trigger(type);
  };

  const addedData = reducerDataById[id];

  useEffectOnce(() => {
    if (addedData) {
      setValue(
        'boardingPassOrBookingReferenceFile',
        addedData.boardingPassOrBookingReferenceFile,
      );
      setValue('passportOrIdFile', addedData.passportOrIdFile);
    }
  });

  return (
    <form onSubmit={handleSubmit(handleAddPax)}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="greyscale.100"
        borderBottomRadius="xl"
      >
        <Box py={{ base: 4, md: 5 }} width="100%">
          {AIR_PAYOUT_DOCUMENTS.map((document) => {
            const { name, tKey: label } = document;
            return (
              <Controller
                name={name}
                key={name}
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={null}
                render={({
                  field: { value },
                  fieldState: { invalid, error },
                }) => {
                  return (
                    <Box
                      width="100%"
                      px={{ base: 4, md: 5 }}
                      pb={{ base: 4, md: 5 }}
                    >
                      <AirDocumentsUploader
                        documents={value ? [value] : []}
                        onDrop={(files) => handleDocument(name, files)}
                        onDelete={() => handleDocumentDelete(name)}
                        isInvalid={invalid}
                        multipleFiles={false}
                        error={error}
                        label={t(label)}
                        dataTestId={dataTestIdFormat(name, id)}
                      />
                    </Box>
                  );
                }}
              />
            );
          })}
        </Box>

        {canEdit ? (
          <Flex
            px={{ base: 4, md: 5 }}
            pb={{ base: 4, md: 5 }}
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="primary"
              size="s"
              onClick={handleSubmit(handleAddPax)}
              width={{ base: '100%', md: 'initial' }}
              data-testid={`details-add-button-${id}`}
            >
              {addedData ? t('common.update') : t('common.add')}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </form>
  );
};

export default DetailsForm;
