import config from 'config/config';
import { convertDomainUrl } from 'utils/domains';

const AUTH_API_VERSION = config.authApiVersion;

export const AUTH_API_URL = convertDomainUrl(
  window.location.origin,
  import.meta.env.VITE_AUTH_URL || '',
);

const SSO_AUTH_URL = `${AUTH_API_URL}/api/${AUTH_API_VERSION}/auth`;

export enum SSO_PROVIDER {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  FACEBOOK = 'facebook',
}

export const oAuthLink = (provider: SSO_PROVIDER) => {
  const returnUrl = new URL(window.location.href);
  return `${SSO_AUTH_URL}/${provider}?return_url=${encodeURIComponent(returnUrl.toString())}`;
};
