import { filter } from 'lodash';

import {
  getCountriesSelectOptions,
  type SelectOption,
} from 'utils/i18n/countries';

import paymentsFieldsConfig from './paymentsFieldsConfig';
import { InsuranceType } from '@airhelp/plus';

export function getCountriesPayoutSelectOptions(
  locale: string,
): SelectOption[] {
  const countries = getCountriesSelectOptions(locale);

  return filter(countries, (item) =>
    Object.keys(paymentsFieldsConfig).includes(item.value),
  );
}
export const selectFailed = (
  data,
  transactionReference: string | null,
  insuranceType: InsuranceType,
) => {
  if (!data) {
    return [];
  }
  const isAirLuggage = insuranceType === InsuranceType.AIR_LUGGAGE;

  if (isAirLuggage && transactionReference && data) {
    const tr = decodeURIComponent(transactionReference);
    return data.filter(
      (x) => x.payoutUpdateAllowed && x.transactionReference === tr,
    );
  }
  return data.filter((x) => x.payoutUpdateAllowed);
};

export const selectEligible = (data) => {
  if (!data) {
    return [];
  }
  return data.filter((x: { payoutAllowed: boolean }) => x.payoutAllowed);
};

export const anyDetailsFormInvalid = (d: Record<string, boolean>) => {
  return Object.values(d).some((v) => !v);
};
