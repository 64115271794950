import React from 'react';
import { useTranslation } from 'react-i18next';

import perkLogo from 'assets/images/perks/logo-high-pass.svg';
import { useTrackEvent } from 'utils/tracking/hooks';

import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { Locale } from 'contexts/LocaleContext/locales';
import PerkDrawerContent from '../PerkDrawerContent';

const SUPPORTED_LANGUAGES: Locale[] = ['en', 'es', 'fr', 'it', 'ru'];
const TRACKING_PROVIDER_NAME = 'highpass_fasttrack';

interface IComponent {
  code: string;
  discount: number;
  imageText: string;
  imageSrcMobile: string;
}

const FastTrack: React.FC<IComponent> = ({
  code,
  discount,
  imageText,
  imageSrcMobile,
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const { locale } = useLocale();

  const benefits = [
    {
      content: t('dashboard.perks.fast_track.no_queues'),
      key: 'skip_the_queues',
    },
    {
      content: t('dashboard.perks.fast_track.less_stress'),
      key: 'less_stress',
    },
    {
      content: t('dashboard.perks.fast_track.dedicated_lane'),
      key: 'dedicated_lane',
    },
    {
      content: t('dashboard.perks.fast_track.available_for_family'),
      key: 'available_for_family',
    },
  ];

  const getSiteUrl = (locale: Locale) => {
    let selectedLocale = locale;

    if (!SUPPORTED_LANGUAGES.includes(locale)) {
      selectedLocale = 'en';
    }
    return `https://highpass.aero/${selectedLocale}/?apiKey=353f870f-3f11-6058-e442-85966e7255f3&categories=FastTrack`;
  };

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(getSiteUrl(locale), '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <PerkDrawerContent
      heading={imageText}
      imageSrcMobile={imageSrcMobile}
      perkLogo={perkLogo}
      discount={discount}
      subHeading="dashboard.perks.fast_track.percentage_off_your_fast_track"
      descriptionPrimary={t('dashboard.perks.fast_track.skip_the_queues')}
      benefits={benefits}
      code={code}
      onCodeCopied={onCodeCopied}
      promoCodeText={t('dashboard.perks.use_it_on', { url: 'highpass.aero' })}
      goToSite={goToSite}
      buttonLabel={t('dashboard.perks.fast_track.get_pass')}
      imageTextColor="greyscale.100"
    />
  );
};

export default FastTrack;
