import React from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import track from 'react-tracking';

import CompleteView from 'components/Trips/Complete/CompleteView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { ProductName } from '@airhelp/plus';

const CompletePage: React.FC = () => {
  usePageTracking();

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data: offersData,
  } = useComputedOffers({
    params: {
      // @todo: remove hardcoded values after BE implementation
      renew: false,
      upgrade: false,
      channel: '',
    },
  });

  if (isLoadingOffers) {
    return <PageLoader />;
  }

  if (isErrorOffers) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  if (!offersData) {
    return null;
  }

  const offers = offersData.offers;
  const product = offers[ProductName.UPGRADE_TO_TRIP_COMPLETE];
  return <CompleteView product={product} />;
};

export default track({ page_type: 'Trips.Upgrade.Complete' })(CompletePage);
