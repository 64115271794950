import { get, put, patch, WEBAPP_API_URL, webappGateway } from 'api/ky';
import { setupCsrfToken } from 'api/ky/csrf';
import { type IUserUpdateData } from 'models/User';

export const SESSION_URL = `${WEBAPP_API_URL}/api/users/me`;
export const UPDATE_DATA_URL = `${WEBAPP_API_URL}/api/users/update_data`;
export const ACCEPT_MARKETING_CONSENT = `${WEBAPP_API_URL}/api/users/accept_marketing_consent`;

export const fetchSession = async (authRetryEnabled = true) => {
  return await get(SESSION_URL, {
    options: {
      hooks: {
        afterResponse: [
          async (_request, _options, response) => {
            if (response.headers.get('X-Csrf-token')) {
              setupCsrfToken(response.headers.get('X-Csrf-token'), 'webapp');
            }
            return response;
          },
        ],
      },
    },
    authRetryEnabled,
    kyInstance: webappGateway,
  });
};

export const updateData = async (userData: IUserUpdateData) => {
  return await put(
    UPDATE_DATA_URL,
    { user: userData },
    { kyInstance: webappGateway },
  );
};

export const acceptMarketingConsent = async () => {
  return await patch(
    ACCEPT_MARKETING_CONSENT,
    {},
    { kyInstance: webappGateway },
  );
};
