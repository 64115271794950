import { useState } from 'react';

import useEffectOnce from 'hooks/useEffectOnce';
import { type CurrencyCode } from 'stores/preferences/currencies';
import { ProductName } from '@airhelp/plus';
import { useCreatePayment } from '@airhelp/plus/react';

const useClientSecret = ({
  email,
  productName,
  currency,
  recurring = false,
  upgrade = false,
  channel,
  locale,
  tripUuid,
}: {
  email: string;
  productName?: ProductName;
  currency: CurrencyCode;
  recurring?: boolean;
  channel: string;
  locale: string;
  upgrade?: boolean;
  tripUuid?: string;
}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const { mutateAsync: createStripeClient } = useCreatePayment();

  useEffectOnce(() => {
    if (!productName) {
      return;
    }

    const fetch = async () => {
      setPending(true);
      try {
        const data = await createStripeClient({
          email,
          productName,
          currency,
          recurring,
          channel,
          locale,
          tripUuid,
          upgrade,
        });
        setClientSecret(data?.clientSecret);
      } catch (error) {
        setError(error);
      } finally {
        setPending(false);
      }
    };

    fetch();
  });

  return { clientSecret, pending, error };
};

export default useClientSecret;
